<template>
	<div class="form-inner edit_inner">
		<div class="need_height">
			<div class="form-head" v-if="globalProperties.formHeader.isShow">
				<div v-if="globalProperties.formHeader.type === '文字'" class="txt">{{globalProperties.formHeader.textCont}}</div>
				<div v-else class="img">
					<img :src="globalProperties.formHeader.imgSrc">
				</div>
			</div>
			<div class="form-title" v-if="globalProperties.formTitle.isShow">{{globalProperties.formTitle.textCont}}</div>
			<div class="form-descript" v-if="globalProperties.formDescript.isShow">{{globalProperties.formDescript.textCont}}</div>		
			<div class="parent-list">
				<div class="parent-item" v-for="(item,index) in list" :key="index">
					<div class="c-item" v-for="(o,i) in item.children" :key="i">
						<ybdWidget :detial="o" />
						<div class="cover" v-show="JSON.stringify(astrictControl)=='{}'||formDataId"></div>
					</div>
				</div>
			</div>	
			<el-form :model="form" label-width="80px" :inline='true' class="yzm" v-if="verifyType == 1">
				<el-form-item label="验证码" prop="gifCode" :rules='[{ required:true,message:"验证码不能为空", trigger: "blur" }]'>
					<el-input v-model="form.gifCode"></el-input>
				</el-form-item>
				<el-form-item>
					<el-image :src="verificationCode" @click="getGifCode"></el-image>
				</el-form-item>
			</el-form>
			<div class="submit_btn" v-if="globalStyle.submitBtnStyle">
				<el-button type="primary" @click="formSubmit" v-if="logicFlag && !formDataId">{{globalStyle.submitBtnStyle.text}}</el-button>
				<el-button type="primary" v-else>{{globalStyle.submitBtnStyle.text}}</el-button>
			</div>
		</div>
		<iframe v-if="identityType=='user'" :src="jzHost + '/forminitSuccessback.html?formId=' + formId + '&height=' + formHeight + '&width=' + formWidth" frameborder="0" width="0" height="0"></iframe>
	</div>
</template>
<script>
import $ from "jquery";
import ybdWidget from "@/components/ybdWidget";
import { get,post,getGifCode} from "/src/services/ajax_ucenter.js";
export default {
	components:{
		ybdWidget,
	},
	props:{
		logicFlag:{
			type:Boolean
		},
		astrictControl:{
			type:Object
		}
	},
	data() {
		return {
			formToken : sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			identityType:sessionStorage.getItem('identityType')?sessionStorage.getItem('identityType'):'',
			formId:'',
			email:'',
			formDataId:'',
			userId:'',
			verifyType:0,
			verificationCode:'',
			form:{
				gifCode:''
			},
			urlType:0,
			jzHost: '',
			globalStyle: {
				themeColor: '',
				formWidth: '',
				contStyle: {},
				itemStyle: {},
				labelStyle: {},
				submitBtnStyle: {}
			},
			globalProperties: {
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {}
			},
			mGlobalStyle: {
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {},
				contStyle: {},
				itemStyle: {},
				labelStyle: {},
				inputStyle: {},
				submitBtnStyle: {}
			},
			list: [],
			canAnswer: 1,
			cantMessage: '',
			startPassword: 0,
			password: '',
			userInputPsd: '',
			id: '',
			formWidth: '',
			formHeight: ''
		}
	},
	watch:{
		list(){    
			this.$nextTick(function(){
				this.formWidth = $(".need_height").outerWidth(true);
				this.formHeight = $(".need_height").outerHeight(true);
			});
		}
	},
	mounted(){
		this.identityType = this.$route.query.identityType?this.$route.query.identityType:sessionStorage.getItem('identityType');
		this.formId = this.$route.query.formId?this.$route.query.formId:sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'';
		this.formDataId = this.$route.query.dataId?this.$route.query.dataId:'';
		this.email = this.$route.query.email?this.$route.query.email:'';
		this.userId = this.$route.query.userId?this.$route.query.userId:'';
		if(this.identityType == 'admin'){
			if(this.userId){
				this.getTplFormData(this.formId); //超管后台查看用户发布到答题广场的表单
			}else{
				this.getFormTpl(); //超管后台查看模板
			}
		}else{
			if(this.userId){
				this.getTplFormPreview(this.formId,this.userId)
			}else if(this.formDataId){
				this.getUserFormData(this.formId,this.formDataId);
			}else{
				if(this.logicFlag){
					this.getFormData();
				}else{
					this.getTplFormData(this.formId);
				}
			}
		}
		this.jzHost = document.referrer;
	},
	methods: {
		// 预览页获取模板表单数据
		getTplFormData(formId){
			const _this = this;
			get('/formadmin/formmodel/from_preview.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					_this.list = formObj.list;
					_this.globalStyle = res.data.pcStyle?res.data.pcStyle:formObj.globalStyle;
					_this.mGlobalStyle = res.data.mobileStyle?res.data.mobileStyle:formObj.mGlobalStyle;
					console.log(formObj)
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 详情页获取模板表单数据
		getFormData(){
			const _this = this;
			get('/formadmin/formmodel/from_data_add.jhtml',_this.astrictControl).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);console.log(formObj)
					_this.list = formObj.list;
					_this.globalStyle = res.data.pcStyle?res.data.pcStyle:formObj.globalStyle;
					_this.mGlobalStyle = res.data.mobileStyle?res.data.mobileStyle:formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.list.forEach(item=>{
						if(item.children){
							item.children.forEach(o=>{
								o.itemStyle = _this.globalStyle.itemStyle;
								o.labelStyle = _this.globalStyle.labelStyle;
							})
						}
					})
					_this.formId = formObj._id;
					_this.verifyType = res.data.verifyType?res.data.verifyType:0;
					if(_this.verifyType == 1){
						_this.getGifCode()
					}
					_this.urlType = res.data.urlType?res.data.urlType:0;
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		getGifCode(){
			const _this = this;
			getGifCode('/com/gifCode.jhtml',{rand:'form'}).then(res => {
				_this.verificationCode = 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 详情页获取用户填写数据
		getUserFormData(formId,formDataId){
			const _this = this;
			get('/formadmin/formmodel/from_data_detail.jhtml',{formId,formDataId}).then(res => {
				if(res.code == 200){
					_this.logicFlag = true;
					let formObj = JSON.parse(res.data.formDataObj);console.log(formObj)
					_this.list= formObj.quesDate;
					_this.globalStyle = formObj.pcStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.mGlobalStyle = formObj.mobileStyle;
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板中心表单详情
		getFormTpl(){
			const _this = this;
			let param = {
				formId:_this.formId,
			}
			get('/superadm/formmodel/show.do',param).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);console.log(formObj)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.list.forEach(item=>{
						if(item.children){
							item.children.forEach(o=>{
								o.itemStyle = _this.globalStyle.itemStyle;
								o.labelStyle = _this.globalStyle.labelStyle;
							})
						}
					})
					
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 模板中心预览获取模板表单数据
		getTplFormPreview(formId){
			const _this = this;
			get('/formadmin/superAdm_preview.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					console.log(formObj)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.list.forEach(item=>{
						if(item.children){
							item.children.forEach(o=>{
								o.itemStyle = _this.globalStyle.itemStyle;
								o.labelStyle = _this.globalStyle.labelStyle;
							})
						}
					})
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 加载样式
		loadStyle(){
			this.$nextTick(function () {
				// 表单样式
				document.documentElement.style.setProperty("--theme-custom-color",this.globalStyle.themeColor);
				const formBg = this.globalProperties.formBg;
				let formBgStr = '';
				if(formBg.isShow){
						if(formBg.type == '颜色'){
							formBgStr = formBg.color;
						}else{
							if(formBg.imgSrc){
								formBgStr = 'url(' + formBg.imgSrc + ')';
							}
						}
					}else{
						formBgStr = '#fff';
					}
				$(".form-inner").css({
					width: this.globalStyle.formWidth + 'px',
					background: formBgStr,
					backgroundSize: 'cover'
				})
				// 页眉
				const formHeaderObj = this.globalProperties.formHeader.textStyle;
				$(".form-head .txt").css({
					fontSize: formHeaderObj.fontSize + "px",
					color: formHeaderObj.color,
					fontWeight: formHeaderObj.fontWight,
					fontStyle: formHeaderObj.fontStyle,
					textDecoration: formHeaderObj.textDecoration,
					textAlign: formHeaderObj.textAlign == "居左"? "left":formHeaderObj.textAlign == "居中"?"center":"right",
					backgroundColor: formHeaderObj.backgroundColor,
					padding: formHeaderObj.paddingVertical + "px " + formHeaderObj.paddingHorizontal + "px",
				});
				$(".form-head .img").css({
					height: this.globalProperties.formHeader.imgHeight + 'px'
				})
				// 表单标题样式
				const formTitleObj = this.globalProperties.formTitle.textStyle;
				let formTitleBgStr = formTitleObj.bgType == "默认"? "unset":formTitleObj.bgType == "颜色"? formTitleObj.backgroundColor:"url(" + formTitleObj.backgroundImage + ")";
				$(".form-title").css({
					fontSize: formTitleObj.fontSize + "px",
					color: formTitleObj.color,
					fontWeight: formTitleObj.fontWight,
					fontStyle: formTitleObj.fontStyle,
					textDecoration: formTitleObj.textDecoration,
					padding: formTitleObj.paddingTop + "px " + formTitleObj.paddingHorizontal + "px " + formTitleObj.paddingBottom + 'px',
					background: formTitleBgStr,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				});
				// 表单描述样式
				const formDescriptObj = this.globalProperties.formDescript.textStyle;
				$(".form-descript").css({
					fontSize: formDescriptObj.fontSize + "px",
					color: formDescriptObj.color,
					fontWeight: formDescriptObj.fontWight,
					fontStyle: formDescriptObj.fontStyle,
					textDecoration: formDescriptObj.textDecoration,
					padding: formDescriptObj.paddingVertical + "px " + formDescriptObj.paddingHorizontal + "px",
				});
				// 内容区样式
				const contStyleObj = this.globalStyle.contStyle;
				$(".parent-list").css({
					padding: contStyleObj.paddingTop + "px " + contStyleObj.paddingHorizontal + "px " + contStyleObj.paddingBottom + 'px',
				});
				// 提交按钮
				const submitBtnObj = this.globalStyle.submitBtnStyle;
				$(".submit_btn .el-button").css({
					width: submitBtnObj.width + "px",
					height: submitBtnObj.height + "px",
					borderWidth: submitBtnObj.borderWidth + "px",
					borderStyle: submitBtnObj.borderStyle,
					borderColor: submitBtnObj.borderColor,
					borderRadius: submitBtnObj.borderRadius + "px",
					backgroundColor: submitBtnObj.backgroundColor,
					fontSize: submitBtnObj.fontSize + "px",
					fontWeight: submitBtnObj.fontWight,
					fontStyle: submitBtnObj.fontStyle,
					color: submitBtnObj.color,
					textDecoration: submitBtnObj.textDecoration,
				});
				$(".submit_btn").css({
					padding: submitBtnObj.paddingTop + "px " + submitBtnObj.paddingHorizontal + "px " + submitBtnObj.paddingBottom + 'px',
					justifyContent: submitBtnObj.textAlign == '居左'?'flex-start':submitBtnObj.textAlign == '居中'?'center':'flex-end',
				});
			})
		},
		
		// 表单提交
		formSubmit(){
			const _this = this;
			for (let i = 0; i < _this.list.length; i++) {
				const element = _this.list[i];
				if(element.children){
					for (let j = 0; j < element.children.length; j++) {
						const e = element.children[j];
						if(e.topic_required && (e.value === '' || e.value.length == 0)){
							_this.$message.error(`${e.label}不能为空！`);
							return false;
						}
					}
				}
				
			}
			let jsonData = {
				_id: _this.formId,
				quesDate: _this.list,
				pcStyle:{..._this.globalStyle,..._this.globalProperties},
				mobileStyle:_this.mGlobalStyle,
			};
			let params = {
				verifyType:_this.verifyType,
				verificationCode:_this.form.gifCode,
				formObj:JSON.stringify(jsonData),
			}
			if(_this.verifyType == 1 && _this.form.gifCode == ''){
				_this.$message.error(`验证码不能为空！`);
				return false;
			}else{
				post('/formadmin/formmodel/from_data_commit.jhtml',params).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '提交成功！',
							onClose(){
								if(_this.identityType != 'user'){
									if(_this.urlType == 1){
										if(res.data.linkHtml != ''){
											window.location.href = res.data.linkHtml;
										}else{
											window.location.href = "https://bd.ruiyi126.com/";
										}
									}else{
										_this.formDateId = res.data.formDateId
										_this.$router.push({
											path:'/detail',
											query:{
												dataId:_this.formDateId,
												formId:_this.formId
											}
										})
										window.location.reload()
									}
								}
							}
						})
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.form-inner{
		height:100%;
		margin: 0 auto;
		overflow-y: auto;
		.form-head{
			.img{
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.form-title{
			text-align: center;
		}
		.parent-list{
			.parent-item{
				display: flex;
				.c-item{
					flex: 1;
					overflow: hidden;	
					position: relative;
					.cover{
						position: absolute;
						left: 0;top: 0;right:0;
						bottom: 0;z-index: 10;
					}			
				}
				.c-item + .c-item{
					.item{
						border-left: none !important;
					}
				}
			}
		}
		.yzm{
			width:410px;
			margin:0 auto;
			.el-image{height: 40px;}
		}
		.submit_btn{
			display: flex;
		}
		.limit-page{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 0 0 20vh;
			.el-image{
				.el-icon-lock{
					font-size: 100px;
					color: #ccc;
				}
			}
			.pass-word{
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 5vh 0 0;
				.el-input{
					margin: 0 10px 0 0;
				}
			}
		}
		.cant-answer{
			height: 100%;
			.el-result{
				height: 90%;
			}
		}
	}
</style>